import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { UserPrerequisite } from '../../domain/entities/user.entity';
import { CurrentUserController } from '../controllers/current-user.controller';
import { RedirectionsService } from '../services/redirections.service';

// TODO: Use role based authorization logic

export interface UserPrerequisiteData {
  forPrerequisite: UserPrerequisite;
}

export const userPrerequisitesGuard: CanActivateFn = async (route: ActivatedRouteSnapshot) => {
  const usersController = inject(CurrentUserController);
  const redirections = inject(RedirectionsService);
  const currentUser = usersController.getCurrentUser();

  if (!currentUser) {
    return redirections.signInUrlTree;
  } else if (currentUser.unmetPrerequisites?.[0]) {
    const nextPrerequisite = currentUser.unmetPrerequisites[0]!;

    if (route.data['forPrerequisite'] === nextPrerequisite) {
      return true;
    }

    switch (nextPrerequisite!) {
      case 'verify_email':
        return redirections.urlToChallenge('verify_email');
      // case 'accept_terms':
      //   return router.createUrlTree(['/accept-terms']);
      case 'complete_profile':
        return redirections.accountInitializationUrlTree;
    }
  }

  return true;
};
